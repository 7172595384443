import {
  RightOutlined,
} from "@ant-design/icons";
import UserService from "../../../services/UserService";
import HomeSvg from "./../../../assets/home.svg";
import BriefcaseSvg from "./../../../assets/Briefcase.svg";
import ScenarioSvg from "./../../../assets/Scenario.svg";
import BusinessRreportSvg from "./../../../assets/BusinessRreport.svg";
import OthersSvg from "./../../../assets/others.svg";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import {transformText } from "../../../utils/Common";
import { setMenuPortfolio } from "../../../utils/settingCommon";
import { Tag } from "antd";

export const portfolioRoute=({moduleName})=>
{ 

    // if( moduleName==="business_application")
    // {
    //   return "application-list";
    // }
    // if( moduleName==="business_capability")
    // {
    //   return "business-capability-master";
    // }
    // if( moduleName==="business_processes")
    // {
    //   return "business-process";
    // }
    // if( moduleName=="initiative")
    // {
    //   return "portfolio-initiative"
    // }
    // if( moduleName==="integration")
    // {
    //   return "portfolio-integration"
    // }else{
    //   return "portfolio";
    // }
    return "portfolio";
}

const getPortfolioMenuList = async () => {
    let page = 1;
    let limit = 100;
    let finalMenu = [];
    let menuList = {};
    let SideMenuItems2 = [];

    await PortfolioSettingServices.getList({ page, limit }).then((result) => {
        menuList = result;
        setMenuPortfolio(menuList?.data?.data);
        menuList?.data?.data.map((item) => { 
        
        let mm = {
            //key: item.moduleName,
            key:portfolioRoute({moduleName:item.moduleName})+'/'+transformText( item.moduleName.replace("_"," "),"pascal"),
            // label: item.name,
            label:<span><span className="navportfolio_indicatecolor" style={{background:item.color}}></span>{item?.name}</span>,
            icon: <RightOutlined />,
            moduleName: transformText( item.moduleName.replace("_"," "),"pascal"), 
            moduleID: item.id,
            color: item.color,
            style:{borderColor:item.color,color:item.color},
            // onmouseover:{color:item.color},
            className:'left-sidebar-portfolio-submenu'
        };
        finalMenu.push(mm);
        });

        SideMenuItems2 = [
        {
            key: "",
            label: `Home`,
            icon: <img src={HomeSvg} alt={`Home`} className="side-menu-icon" />,
        },
        {
            key: "portfolio",
            label: `Portfolio`,
            icon: (
            <img
                src={BriefcaseSvg}
                alt={`Portfolio`}
                className="side-menu-icon"
            />
            ),
            children: 
            finalMenu,
        },
        {
            key: "landscape-scenario",
            navigate: false,
            label: `Landscape`,
            icon: (
                <img
                    src={ScenarioSvg}
                    alt={`Scenarios`}
                    className="side-menu-icon"
                />
            ),
            children: [
                {
                    key: "add-new-scenario",
                    navigate: false,
                    label: `New`,
                    icon: <RightOutlined />,
                },
                {
                    key: "scenario-list",
                    navigate: false,
                    label: `List`,
                    icon: <RightOutlined />,
                },
                {
                    key: "compare-scenario",
                    navigate: false,
                    label: `Compare`,
                    icon: <RightOutlined />,
                },
            ],
          },
        {
            key: "scenarios",
            navigate: false,
            label: `Scenarios`,
            icon: (<img src={ScenarioSvg} alt={`Scenarios`} className="side-menu-icon" />),
            children: [
                {
                    key: "start-new-scenario",
                    navigate: false,
                    label: `New`,
                    icon: <RightOutlined />,
                },
                {
                    key: "list-scenario",
                    navigate: false,
                    label: `List`,
                    icon: <RightOutlined />,
                },
                {
                    key: "compare-scenario-v2",
                    navigate: false,
                    label: `Compare`,
                    icon: <RightOutlined />,
                },
            ],
        },
        {
            key: "reports",
            navigate: false,
            label: `Reports`,
            icon: (<img src={BusinessRreportSvg} alt={`Reports`} className="side-menu-icon" /> ),
            children: [
                {
                    key: "reports/application-landscape",
                    navigate: false,
                    label: `Application Landscape`,
                    icon: <RightOutlined />,
                },
                {
                    key: "reports/integration-landscape",
                    navigate: false,
                    label: `Integration Landscape`,
                    icon: <RightOutlined />,
                },
                {
                    key: "reports/technology-landscape",
                    navigate: false,
                    label: `Technology Landscape`,
                    icon: <RightOutlined />,
                },
                {
                    key: "reports/business-capabilty-landscape",
                    navigate: false,
                    label: `Business Capability Landscape`,
                    icon: <RightOutlined />,
                },
                {
                    key: "reports/business-capabilty-summary-report",
                    navigate: false,
                    label: `Business Capability Summary Report`,
                    icon: <RightOutlined />,
                },
                {
                    key: "reports/data-completeness-report",
                    navigate: false,
                    label: `Data Completeness`,
                    icon: <RightOutlined />,
                },
            ],
        },
        {
            key: "others",
            navigate: false,
            label: `Others`,
            icon: <img src={OthersSvg} alt={`Others`} className="side-menu-icon" />,
            children: [
            {
                key: "others-settings",
                navigate: false,
                label: `Settings`,
                icon: <RightOutlined />,
            },
            {
                key: "others-surveys",
                navigate: false,
                label: `Surveys`,
                icon: <RightOutlined />,
            },
            {
                key: "others-help",
                navigate: false,
                label: `Help`,
                icon: <RightOutlined />,
            },
            ],
        },
        ];
    });

    return SideMenuItems2;
};

const SideMenu =  async  () => {

    const SideMenuItems2 =   await getPortfolioMenuList();

    if (!UserService.hasRole(["Admin"])) {
        SideMenuItems2.forEach(async (item, index, object) => {
            if (item.key === "data-import") {
                object.splice(index, 1);
            } else {
                if (item?.children) {
                    item?.children.forEach((item1, index1, object1) => {
                        if (item1.key === "start-new-scenario") {
                            object1.splice(index1, 1);
                        }
                    });
                }
                
                
                if (item?.key==="others") {
                    await item?.children.forEach(async (item1, index1, object1) => {
                        if (item1.key === "others-surveys") {
                            await object1.splice(index1, 1);
                        }else if (item1.key === "others-settings") {
                            await object1.splice(index1, 1);
                        }if (item1.key === "others-help") {
                            await object1.splice(index1, 1);
                        }else if (item1.key === "landscape-scenario") {
                            item1?.children.forEach(async (item2, index2, object2) => {
                                if (item2.key === "add-new-scenario") {
                                    await object2.splice(index2, 1);
                                }
                            });
                        }
                    });
                }
            }
        });
    }
    return SideMenuItems2;
};

export default SideMenu;
