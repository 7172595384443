import React, { useEffect, useState } from 'react';
import { Layout, Col, Row  } from 'antd';
import './AppHeader.scss';
import { Link } from 'react-router-dom';
import { svgLogo } from '../../../assets/images';
import HeaderMenus from './HeaderMenus';
import HeaderMenusItems from './HeaderMenusItems';
import GlobalSearch from './GlobalSearch';
import "bootstrap";
import SideMenu from '../sideBar/SideMenuItems';
import SideBar from '../sideBar/SideBar';
// import SideBar from '../sideBar/SideBar';



const { Header } = Layout;



function AppHeader() {

    // const [collapsed, setCollapsed] = useState(false);
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        let menuTemp = [];
        //menuTemp= await SideMenu();

        SideMenu().then((result) => {
            menuTemp = result;
            setMenu((menuTemp?.length && menuTemp) || []);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SideMenu]);

    return (
        <Header className="header">
            <nav className="menuBar">
                <Row>
                    <Col span={2} className="logo">
                        <Link to="/">
                            {/* <img src={svgLogo} alt="logo" /> */} 
                            <h4>Connected Architecture</h4>
                        </Link>
                    </Col>
                  
                           <Col span={16} className='navbar_bx'><SideBar menus={menu}/> 
                        <GlobalSearch />
                    </Col>          
                    <Col span={6}>
                    <HeaderMenus menus={HeaderMenusItems}/>
                    </Col>
                </Row>
            </nav>
        </Header>
    );
}

export default AppHeader;
